<template>
  <div class="pressure gis-main">
    <div class="pressure-box">
      <div class="pressure-item">
        <div class="btns">
          <el-button type="primary" :disabled="isDisabled" icon="el-icon-plus" size="small" @click="pressureAdd">新增</el-button>
          <span style="margin-left:10px">未配置默认:<span style="display:inline-block;width:15px;height:15px;background:#00d7ff;margin-left:5px"></span></span>
        </div>
        <el-table
          :data="pressureTableData"
          :header-cell-style="{ background: '#FAFAFA' }"
          border
          height="calc(100vh - 300px)"
          style="width: 100%"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictCodes" align="center" label="确认状态">
            <template #default="{ row }">
              <div v-if="row.id && !row.isEditor">{{ row.showName }}</div>
              <div v-else>
                <el-select
                  v-if="!row.id"
                  v-model="row.dictCodesData"
                  placeholder="请选择"
                  @change="dictCodesChange($event, row)"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="row.isEditor"
                  v-model="row.dictCodesData"
                  placeholder="请选择"
                  @change="dictCodesChange($event, row)"
                >
                  <el-option
                    v-for="item in optionsAlter"
                    :key="item.dictCode"
                    :label="item.dictName"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="colour" align="center" width="100" label="显示颜色">
            <template #default="{ row }">
              <el-color-picker v-model="row.colour" :disabled="isEditor(row)"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column align="center" width="250" label="操作">
            <template #default="{ row, $index }">
              <div v-if="row.id && !row.isEditor" class="tableBtn">
              <span class="btn" style="color: #1e9fff" @click="pressureEditor(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getDelete(row)">
                  删除
                </span>
                <span :class="[$index == 0 ? 'disabled' : 'up', 'btn']" @click="upData($index)">
                  上移
                </span>
                <span
                  :class="[isDown($index) ? 'disabled' : 'down', 'btn']"
                  @click="downData($index)"
                >
                  下移
                </span>
              </div>
              <div v-else>
                <el-button
                  v-if="!row.id"
                  size="mini"
                  type="text"
                  style="margin-right: 20px"
                  @click="addSave(row, $index)"
                  >保存</el-button
                >
                <el-button
                  v-else
                  size="mini"
                  type="text"
                  style="margin-right: 20px"
                  @click="update(row, $index)"
                  >保存</el-button
                >
                <el-button type="text" style="color:#000" size="mini" @click="pressureCancel(row, $index)">取消</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Try, Confirm } from '@/decorator'
import SetBase from '../apis/setBase'
export default {
  name: 'PressureLevel',
  data() {
    return {
      pressureTableData: [],
      options: [],
      optionsAlter: [],
      copyRow: new Map(),
      optionsMap: new Map()
    }
  },
  computed: {
    isDisabled() {
      if (!this.pressureTableData.length) return false
      let list = this.pressureTableData.filter(el => el.id)
      return list.length !== this.pressureTableData.length || !this.options.length
    }
  },
  mounted() {
    this.getDictionaries()
    this.getList()
  },
  methods: {
    /*
     *@description: 获取字典
     *@author: 黄丽玲
     */
    async getDictionaries() {
      let res = await SetBase.findNotSonList({ pidDictCode: '30011' })
      this.options = res.data
      res.data.forEach(({ dictCode, dictName }) => this.optionsMap.set(dictCode, dictName))
    },
    async getList() {
      let res = await SetBase.getList({ pidDictCode: '30011' })
      this.pressureTableData = res.data
    },
    async addSave(row, index) {
      row.isEditor = false
      let res = await SetBase.addSave(row)
      this.getDictionaries()
      this.getList()
    },
    async update(row, index) {
      row.isEditor = false
      let res = await SetBase.update(row)
      this.getDictionaries()
      this.getList()
    },
    dictCodesChange(val, row) {
      row.dictCodes = val
      row.showName = this.optionsMap.get(val)
    },
    isEditor(row) {
      return row.id != '' && row.id != null && !row.isEditor
    },
    isDown(index) {
      let { pressureTableData } = this
      let data = pressureTableData.filter(el => el.id)
      return index == data.length - 1
    },

    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    //上移
    upData(index) {
      if (index == 0) return
      this.pressureTableData = this.swapItems(this.pressureTableData, index, index - 1)
      this.setSort()
    },

    //下移
    downData(index) {
      if (this.isDown(index)) return
      this.pressureTableData = this.swapItems(this.pressureTableData, index, index + 1)
      this.setSort()
    },
    async setSort() {
      let { pressureTableData } = this
      let data = pressureTableData.map((el, index) => {
        return {
          id: el.id,
          sort: index + 1
        }
      })
      let res = await SetBase.setSort(data)
    },
    // 钢管新增
    pressureAdd() {
      let obj = {
        dictCodes: '',
        showName: '',
        colour: null,
        id: null,
        pidDictCode: '30011',
        isEditor: true
      }
      this.pressureTableData.push(obj)
    },

    // 钢管编辑
    async pressureEditor(row) {
       let res = await SetBase.findNotSonList({ pidDictCode: '30011' })
       console.log(res.data)
      res.data.forEach(({ dictCode, dictName }) => {
        if (!this.optionsMap.has(dictCode)) {
          this.optionsMap.set(dictCode, dictName)
        }
      })
      if (!this.optionsMap.has(row.dictCode)) {
          this.optionsMap.set(row.dictCode, row.dictName)
        }
      this.optionsAlter = res.data;
      this.optionsAlter.push({dictCode:row.dictCode,dictName:row.dictName})
      let data = JSON.parse(JSON.stringify(row))
      this.copyRow.set(data.id, data)
      this.$set(row, 'dictCodesData', row.dictCode)
       row.dictCodes = row.dictCode
      this.$set(row, 'isEditor', true)
    },
    pressureCancel(row, index) {
      if (row.id) {
        let { copyRow } = this
        let res = copyRow.get(row.id)
        row.colour = res.colour
        row.isEditor = res.isEditor
        row.showName = res.showName
        row.dictCodes = res.dictCodes
        this.copyRow.delete(row.id)
      } else {
        this.pressureTableData.splice(index, 1)
      }
    },
    async getDelete(row) {
      let res = await SetBase.getDelete({ id: row.id })
      this.getDictionaries()
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.pressure-box {
  // display: flex;
  .btns {
    margin-bottom: 10px;
  }
  .pressure-item {
    // flex: 1;
    width: 600px;
  }
  .pepe-left {
    margin-right: 30px;
  }
  .tableBtn {
    .btn {
      cursor: pointer;
      margin: 0 12px;
      &.up {
        color: #1e9fff;
      }
      &.down {
        color: #0dba7b;
      }
    }
  }
}
</style>
